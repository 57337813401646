<template>
  <div class="bgblack">
    <div class="consulting">
      <div class="onlinetit">
        <img src="../assets/image/icon.png" alt="" />
      </div>
      <div class="ypfw flex">首页</div>
      <div class="ypfw flex">生态应用</div>
      <div class="ypfw flex" @click="go">白皮书</div>
      <div class="ypfw flex">关于我们</div>
      <div class="ypfw flex" @click="goEN">简体中文</div>
    </div>
    <div class="content">
      <div class="t1">众神殿之王者归来</div>
      <div class="t2">
        <div>致力为大家提供一个平等，互利，共赢，体验wab3.0</div>
        <div>和区块链技术无限魅力，创新引领，ZSD助力，共赴</div>
        <div>财富盛宴！</div>
      </div>
      <div class="mobil">
        <div v-if="isMobile">
          <img class="bimg" src="../assets/image/pdh.png" alt="Image" />
          <div class="text">平等</div>
        </div>
        <img
          v-else
          class="bimg"
          :src="currentImage2[0]"
          @mouseover="changeImage2(require('../assets/image/pdh.png'), 0)"
          @mouseout="changeImage2(require('../assets/image/pd.png'), 0)"
          alt="Image"
        />
       
        <div v-if="isMobile">
          <img class="bimg" src="../assets/image/hlh.png" alt="Image" />
          <div class="text">互利</div>
        </div>
        <img
          v-else
          class="bimg"
          :src="currentImage2[1]"
          @mouseover="changeImage2(require('../assets/image/hlh.png'), 1)"
          @mouseout="changeImage2(require('../assets/image/hl.png'), 1)"
          alt="Image"
        />
        <div v-if="isMobile">
          <img class="bimg" src="../assets/image/gyh.png" alt="Image" />
          <div class="text">共赢</div>
        </div>

        <img
          v-else
          class="bimg"
          :src="currentImage2[2]"
          @mouseover="changeImage2(require('../assets/image/gyh.png'), 2)"
          @mouseout="changeImage2(require('../assets/image/gy.png'), 2)"
          alt="Image"
        />
      </div>
    </div>
    <div class="bottomIcon" v-if="isMobile">
      <img
        class="bimg"
        @click="goICon(1)"
        src="../assets/image/niao.png"
        alt="Image"
      />
      <img
        class="bimg"
        @click="goICon(2)"
        src="../assets/image/wyj.png"
        alt="Image"
      />
      <img
        class="bimg"
        @click="goICon(3)"
        src="../assets/image/tlgrm.png"
        alt="Image"
      />
    </div>
    <div class="bottomIcon" v-else>
      <img
        class="bimg"
        @click="goICon(1)"
        :src="currentImage[0]"
        @mouseover="changeImage(require('../assets/image/niaoh.png'), 0)"
        @mouseout="changeImage(require('../assets/image/niao.png'), 0)"
        alt="Image"
      />
      <img
        class="bimg"
        @click="goICon(2)"
        :src="currentImage[1]"
        @mouseover="changeImage(require('../assets/image/wyjh.png'), 1)"
        @mouseout="changeImage(require('../assets/image/wyj.png'), 1)"
        alt="Image"
      />
      <img
        class="bimg"
        @click="goICon(3)"
        :src="currentImage[2]"
        @mouseover="changeImage(require('../assets/image/tlgrmh.png'), 2)"
        @mouseout="changeImage(require('../assets/image/tlgrm.png'), 2)"
        alt="Image"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      isMobile: false,
      currentImage: [
        require("../assets/image/niao.png"),
        require("../assets/image/wyj.png"),
        require("../assets/image/tlgrm.png"),
      ],
      currentImage2: [
        require("../assets/image/pd.png"),
        require("../assets/image/hl.png"),
        require("../assets/image/gy.png"),
      ],
      isDropdownVisible: false,
      currentLanguage: "ZH",
      languages: [
        { code: "ZH", name: "中文(简体) - ZH", path: "/" },
        { code: "EN", name: "English - EN", path: "/EN" },
      ],
    };
  },
  created() {
    this.checkDeviceType();
    window.addEventListener("resize", this.checkDeviceType);
  },
  mounted() {},
  beforeDestroy() {
    window.removeEventListener("resize", this.checkDeviceType);
  },
  methods: {
    checkDeviceType() {
      this.isMobile = window.innerWidth <= 600;
    },
    goICon(v) {
      switch (v) {
        case 1:
          window.open("https://x.com/kuang_jin34363?s=09");

          break;
        case 2:
          window.open("https://discord.com/invite/6Sathyexrm");

          break;
        case 3:
          window.open("https://share1024.com/");

          break;
      }
    },
    go() {
      window.open("https://zsd-1.gitbook.io/zsd");
    },
    goEN() {
      this.$router.push("/EN");
    },
    changeImage(image, i) {
      this.currentImage[i] = image;
      this.$forceUpdate();
    },
    changeImage2(image, i) {
      this.currentImage2[i] = image;
      this.$forceUpdate();
    },
  },
};
</script>
<style src="../assets/lang/lang.css"></style>
<style lang="less" scoped>
/* PC 样式 */
@media (min-width: 601px) {
  .bgblack {
    margin: 0;
    padding: 0;
    height: 100vh;
    background-image: url("../assets/image/BEIJING.gif");
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
    .content {
      color: white;
      position: absolute;
      right: 5%;
      top: 30%;
      letter-spacing: 4px;
      .bimg {
        cursor: pointer;
        margin-right: 50px;
        width: 113.9px;
        height: 131.6px;
        margin-top: 36px;
      }
      .t1 {
        font-size: 40px;
      }
      .t2 {
        font-size: 22px;
        margin-top: 13px;
        div {
          margin-top: 10px;
        }
      }
    }
    .bottomIcon {
      position: absolute;
      bottom: 2.2%;
      right: 13.5%;
      .bimg {
        cursor: pointer;
        margin-right: 20px;
        width: 48px;
        height: 48px;
      }
    }
    .consulting {
      position: fixed;
      left: 0;
      padding: 15px 15%;
      z-index: 999;
      box-sizing: border-box;
      display: flex;
      align-items: center;
      .onlinetit {
        font-size: 20px;
        text-align: center;
        margin-right: 35px;
      }
      .ypfw {
        font-size: 24px;
        color: #fff;
        cursor: pointer;
        justify-content: flex-start;
        height: 40px;
        line-height: 40px;
        margin-bottom: 17px;
        margin-right: 40px;
        padding-bottom: 10px;
        img {
          margin-right: 24px;
        }
        line-height: 36px !important;
      }
      .ypfw:hover {
        color: #c58625;
        border-bottom: 2px solid #c58625;
      }
    }
  }
}
/* 手机样式 */
@media (max-width: 600px) {
  .bgblack {
    height: 100vh;
    background: black;

    background-image: url("../assets/image/BEIJING.gif");
    background-size: 100%;
    background-repeat: no-repeat;
    background-position-y: 35px;
    .content {
      color: white;
      position: relative;
      top: 33%;
      margin: 0 auto;
      width: 87%;
      // letter-spacing: 4px;
      .bimg {
        cursor: pointer;
        width: 79px;
        height: 92px;
        margin-top: 36px;
      }
      .t1 {
        font-size: 24px;
      }
      .t2 {
        font-size: 15px;
        margin-top: 13px;
        div {
          margin-top: 10px;
        }
      }
    }
    .mobil {
      display: flex;
      justify-content: space-between;
      .text{
        font-size: 15px;
        color: #999999;
        margin-top: 6px;
        text-align: center;
      }
    }
    .bottomIcon {
      position: absolute;
      bottom: 2.2%;
      right: 23%;

      .bimg {
        cursor: pointer;
        margin-right: 31px;
        width: 31px;
        height: 31px;
      }
    }
    .consulting {
      position: fixed;
      left: 0;
      width: 100%;
      justify-content: space-around;
      // padding: 15px 15%;
      padding: 20px 0 0 20px;
      z-index: 999;
      box-sizing: border-box;
      display: flex;
      // align-items: center;
      .onlinetit {
        font-size: 20px;
        text-align: center;
        // margin-right: 14px;
        img {
          width: 31px;
          height: 31px;
        }
      }
      .ypfw {
        font-size: 15px;
        color: #fff;
        cursor: pointer;
        justify-content: flex-start;
        height: 40px;
        line-height: 40px;
        white-space:nowrap;
        margin-right: 14px;
        padding-bottom: 10px;
        img {
          margin-right: 24px;
        }
        line-height: 36px !important;
      }
      .ypfw:hover {
        color: #c58625;
      }
    }
  }
}
</style>
